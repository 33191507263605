.sidenav {
    width: 250px;
    transition: width 0.3s ease-in-out;
    height: 150vh;
    background-color: rgb(10,25,41);
    padding-top: 28px;
}
 
.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 60px
}
 
.sideitem {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    color: #B2BAC2;
    text-decoration: none;
}
 
.linkText {
    padding-left: 16px;
}
 
.linkTextClosed {
    composes: linkText;
    visibility: hidden;
}
 
.sideitem:hover {
    background-color: #244f7d1c;

}
 
.menuBtn {
    align-self: center;
    align-self: flex-start; 
    justify-self: flex-end;
    color: #B2BAC2;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 20px;
}
